<div class="api-documentation-wrapper d-flex">
  <!-- Sidebar  -->
  <div class="sidebar-wrapper" [ngClass]="{'show' : status}" (click)="toggleMenu()"></div>
  <nav id="sidebar" [ngClass]="{'show' : status}">
    <div class="hamburger-icon hamburger-icon-sidebar" (click)="toggleMenu()"><img
        src="assets/images/hamburger-icon.svg" alt="hamburger icon"></div>
    <div class="sidebar-header d-flex justify-content">
      <img src="assets/images/reguliq-logo-white.svg" alt="Reguliq Logo" width="176px" height="52px" class="m-auto">
    </div>
    <div class="sidebar-title">
      Welcome Michel Berg
    </div>
    <ul class="sidebar-list list-unstyled">
      <li *ngFor="let menuItem of menuItems; let i = index" [class.active]="menuItem.isActive">
        <a href="javascript:;" (click)="setActiveMenu(i)" [class.active]="menuItem.isActive">
          {{menuItem.name}}
        </a>
      </li>
    </ul>
    <div class="version-number">Version {{version}}</div>
  </nav>


  <!-- Page Content  -->
  <div class="page-content w-100">

    <!-- Header -->
    <div class="page-header d-flex justify-content-between">
      <div class="hamburger-icon" (click)="toggleMenu()"><img src="assets/images/hamburger-icon.svg"
          alt="hamburger icon">
      </div>
      <div class="header-title align-self-center">API Documentation</div>
      <div class=" header-list  ml-auto d-flex m-0 align-self-center">
        <span class="align-self-center notification-icon"></span>
        <div class=" ml-auto d-flex m-0 align-self-center user-list">
          <!-- <span class="align-self-center name-item">Michel Berg</span> -->
          <span class="align-self-center image-item"><img src="assets/images/user-img.png" class="mx-3 user-img"
              alt="user Image"></span>
          <span class="align-self-center arrow-item"></span>
        </div>

      </div>
    </div>
    <div class="page-wrapper">
      <div class="page-main">
        <!-- Page  -->
        <div>
          <div>
            <h2 class="main-title">Issue an Asset Tutorial</h2>
            <p class="main-description">Sed imperdiet erat sed nisi pulvinar, quis aliquam metus hendrerit. Vestibulum
              ante
              ipsum primis in faucibus
              orci luctus et ultrices posuere cubilia curae; Sed feugiat, ipsum ac facilisis cursus, nulla massa luctus
              velit, vulputate vulputate elit risus et leo. Morbi quis diam a tortor cursus mollis vitae sit amet urna.
              Fusce non justo malesuada</p>
          </div>

          <div>
            <h2 class="main-title">Prerequisites</h2>
            <p class="main-description">Sed imperdiet erat sed nisi pulvinar, quis aliquam metus hendrerit. Vestibulum
              ante
              ipsum primis in faucibus
              orci luctus et ultrices posuere cubilia curae; Sed feugiat, ipsum ac facilisis cursus, nulla massa luctus
              velit, vulputate vulputate elit risus et leo. Morbi quis diam a tortor cursus mollis vitae sit amet urna.
              Fusce non justo malesuada</p>
          </div>

          <div>
            <tabset role="tablist">
              <tab heading="Javascript" id="tab1" role="tab">
                <pre><code [highlight]="codeJs" [lineNumbers]="true" ></code></pre>
              </tab>
              <tab heading="PHP" role="tab">
                <pre><code [highlight]="codePHP" [lineNumbers]="true"></code></pre>
              </tab>
              <tab heading="Python" role="tab">
                <pre><code [highlight]="codePy" [lineNumbers]="true"></code></pre>
              </tab>
            </tabset>
          </div>

          <div>
            <h2 class="main-title">Establish trustline</h2>
            <p class="main-description">Sed imperdiet erat sed nisi pulvinar, quis aliquam metus hendrerit. Vestibulum
              ante
              ipsum primis in faucibus
              orci luctus et ultrices posuere cubilia curae; Sed feugiat, ipsum ac facilisis cursus, nulla massa luctus
              velit, vulputate vulputate elit risus et leo. Morbi quis diam a tortor cursus mollis vitae sit amet urna.
              Fusce non justo malesuada</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>