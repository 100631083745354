import { Component, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Reguliq Developers API';
  status: boolean = false;
  version = environment.version;

  codePHP = `// This is a PHP example
  <?php
  echo "<h2>PHP is Fun!</h2>";
  echo "Hello world!<br>";
  echo "I'm about to learn PHP!<br>";
  echo "This ", "string ", "was ", "made ", "with multiple parameters.";
  ?>
  }`;

  codeJs = `// This is a JS example
  const test = "Hello";
  console.log(test);
  function myFunction() {
    document.getElementById("demo1").innerHTML = "Test 1!";
    document.getElementById("demo2").innerHTML = "Test 2!";
  }`;

  codePy = `// This is a Python example
  num1 = 1.5
  num2 = 6.3
  sum = num1 + num2
  print('The sum of {0} and {1} is {2}'.format(num1, num2, sum))`;

  menuItems = [
    { name: 'Fundamentals and Concepts', isActive: true },
    { name: 'Basic Tutorials', isActive: false },
    { name: 'Issue Assets', isActive: false },
    { name: 'Anchor Assets', isActive: false },
    { name: 'Build Applications', isActive: false },
    { name: 'Run a Core Node', isActive: false },
    { name: 'Run an API Server', isActive: false },
    { name: 'Access Historical Data', isActive: false },
    { name: 'Use the Stellar Disbursement', isActive: false },
    { name: 'Tools and SDKs', isActive: false },
    { name: 'Encyclopedia', isActive: false },
    { name: 'Glossary', isActive: false },
    { name: 'Soroban Smart Contracts', isActive: false },
  ];

  constructor(private renderer: Renderer2) { }

  toggleMenu() {
    if (window.innerWidth < 1120) {
      this.status = !this.status;
      if (this.status) {
        this.renderer.addClass(document.body, 'fixed');
      } else {
        this.renderer.removeClass(document.body, 'fixed');
      }
    }
  }

  setActiveMenu(index: number) {
    this.menuItems.forEach(item => (item.isActive = false));
    this.menuItems[index].isActive = true;
    this.toggleMenu();
  }
}
